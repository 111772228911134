import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, iif, Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { CorrelationIdService } from '../../../../../core-lib';
import {
  BasePaymentData,
  IDV_ENV,
  IdvEnvironment,
  QuestionGroup,
  RemoveMedicalRiskResponse,
  RemoveRiskReasonType,
  ReplaceMedicalRiskResponse,
  Request,
  Risk,
  RiskListItem,
  RiskQuestionGroupContext,
} from '../../interfaces';
import { convertDateStringsToDateAndPrecisionDate } from '../../utils/risks';

export interface RiskCategory {
  key: string;
  i18nKey: string;
}

export const riskCategories: RiskCategory[] = [
  { key: 'medicalRisks', i18nKey: 'medical-risks' },
  { key: 'pastimeRisks', i18nKey: 'pastime-risks' },
  { key: 'occupationalHighRisks', i18nKey: 'occupational-high-risks' },
  { key: 'residentialRisks', i18nKey: 'residential-risks' },
  { key: 'financialRisks', i18nKey: 'financial-risks' },
];

export type CategoryFilter = (riskCategory: RiskCategory) => boolean;
export type RiskFilter = (risk: Risk) => boolean;

export function hasRiskCategory(request: Request): CategoryFilter {
  return (riskCategory: RiskCategory): boolean => !!request[riskCategory.key];
}

export function hasFilledRiskCategory(
  request: Request,
  riskFilter: RiskFilter
): CategoryFilter {
  return (riskCategory: RiskCategory): boolean => {
    if (!request[riskCategory.key]) {
      return false;
    }
    const risks = request[riskCategory.key].risks as Risk[];
    if (!risks || !risks.length) {
      return false;
    }
    return risks.some(riskFilter);
  };
}

@Injectable({ providedIn: 'root' })
export class RequestService {
  public request: ReplaySubject<Request>;
  public sessionError: Subject<boolean>;

  constructor(
    private httpClient: HttpClient,
    private correlationIdService: CorrelationIdService,
    @Inject(IDV_ENV) private environment: IdvEnvironment
  ) {
    this.request = new ReplaySubject<Request>(1);
    this.sessionError = new Subject();
  }

  protected get requestApiBaseUrl() {
    return `${
      this.environment.apiHost
    }/idv/${this.correlationIdService.getCorrelationIdFromSessionToken()}`;
  }

  public refetchRequest(): Observable<Request> {
    const url =
      this.environment.flavor.name === 'insured'
        ? `${this.environment.apiHost}/ins/myRequest`
        : this.requestApiBaseUrl;

    return this.httpClient.get<Request>(url).pipe(
      catchError(() => {
        this.sessionError.next(true);
        return EMPTY;
      }),
      tap((request) => {
        this.request.next(request);
      })
    );
  }

  public clearRequest(): void {
    this.request?.next(null);
  }

  protected getFlavoredApiBaseUrl() {
    let url = `${this.environment.apiHost}/${this.environment.flavor.apiPrefix}`;
    if (this.environment.flavor.apiPrefix === 'idv') {
      url = `${url}/${this.correlationIdService.getCorrelationIdFromSessionToken()}`;
    }
    return url;
  }

  public setPaymentData(paymentData: BasePaymentData) {
    return this.httpClient
      .post<void>(`${this.requestApiBaseUrl}/paymentdata`, paymentData)
      .pipe(switchMap(() => this.refetchRequest()));
  }

  public answerPrologueQuestionGroup(
    answeredQuestionGroup: QuestionGroup
  ): Observable<Request> {
    return this.httpClient
      .post<void>(
        `${this.requestApiBaseUrl}/prologue/answer`,
        answeredQuestionGroup
      )
      .pipe(switchMap(() => this.refetchRequest()));
  }

  public proceedToNextQuestionGroup(
    riskId: string,
    answeredQuestionGroup: QuestionGroup
  ): Observable<RiskQuestionGroupContext> {
    return this.httpClient
      .post<RiskQuestionGroupContext>(
        `${this.requestApiBaseUrl}/risks/${riskId}/questionGroups/next`,
        answeredQuestionGroup
      )
      .pipe(
        map((riskQuestionGroupContext) =>
          convertDateStringsToDateAndPrecisionDate(riskQuestionGroupContext)
        )
      )
      .pipe(
        tap((riskQuestionGroupContext) => {
          this.request.next(riskQuestionGroupContext.request);
        })
      );
  }

  public getQuestionGroupById(
    riskId: string,
    questionGroupId: string,
    routedRiskId?: string
  ): Observable<RiskQuestionGroupContext> {
    return iif(
      () => !!routedRiskId,
      this.httpClient.get<RiskQuestionGroupContext>(
        `${this.requestApiBaseUrl}/risks/${riskId}/${routedRiskId}/questionGroups/${questionGroupId}`
      ),
      this.httpClient.get<RiskQuestionGroupContext>(
        `${this.requestApiBaseUrl}/risks/${riskId}/questionGroups/${questionGroupId}`
      )
    ).pipe(
      map((riskQuestionGroupContext) =>
        convertDateStringsToDateAndPrecisionDate(riskQuestionGroupContext)
      )
    );
  }

  public submitRequest(): Observable<void> {
    return this.httpClient.post<void>(`${this.requestApiBaseUrl}/submit`, null);
  }

  public getRequestSummary(): Observable<Blob> {
    return this.httpClient.get(
      `${this.getFlavoredApiBaseUrl()}/${
        this.environment.flavor.summaryPdfPath
      }`,
      {
        responseType: 'blob',
      }
    );
  }

  public findRiskById(riskId: string, callback: (risk: Risk) => void) {
    this.request.pipe(take(1)).subscribe((request: Request) => {
      const risk = this.findOneRisk(request, (r: Risk) => r.id === riskId);
      callback(risk);
    });
  }

  public findRisks$(specificator: (risk: Risk) => boolean): Observable<Risk[]> {
    return this.request.pipe(
      map((request: Request) => this.findRisks(request, specificator))
    );
  }

  public findOneRisk$(specificator: (risk: Risk) => boolean): Observable<Risk> {
    return this.request.pipe(
      map((request: Request) => this.findOneRisk(request, specificator))
    );
  }

  protected findRisks(
    request: Request,
    specificator: (risk: Risk) => boolean
  ): Risk[] {
    return riskCategories
      .filter(hasRiskCategory(request))
      .map((r: RiskCategory) => r.key)
      .reduce((allMatchingRisks: Risk[], riskCategoryKey: string) => {
        const risks = this.flattenRisks(
          request[riskCategoryKey]['risks']
        ).filter(specificator);
        return allMatchingRisks.concat(risks);
      }, []);
  }

  protected findOneRisk(
    request: Request,
    specificator: (risk: Risk) => boolean
  ): Risk {
    const allMatchingRisks = this.findRisks(request, specificator);
    if (allMatchingRisks.length > 1) {
      throw new Error('too many results');
    } else {
      return allMatchingRisks[0];
    }
  }

  public removeMedicalRisk(
    riskId: string,
    reason?: RemoveRiskReasonType
  ): Observable<RemoveMedicalRiskResponse> {
    let requestUrl = `${this.requestApiBaseUrl}/risks/${riskId}`;
    if (reason) {
      requestUrl += `?reasonToDelete=${reason}`;
    }
    return this.httpClient.delete<RemoveMedicalRiskResponse>(requestUrl).pipe(
      switchMap(
        () => this.refetchRequest(),
        (response) => response
      )
    );
  }

  public addMedicalRisk(riskListItem: RiskListItem): Observable<Risk> {
    return this.httpClient
      .post<Risk>(`${this.requestApiBaseUrl}/risks/medical`, riskListItem)
      .pipe(
        switchMap(
          () => this.refetchRequest(),
          (risk: Risk) => risk
        )
      );
  }

  public addUnknownMedicalRisk(riskLabel: string): Observable<Risk> {
    const unknownRisk = {
      label: riskLabel,
    };

    return this.httpClient
      .post<any>(`${this.requestApiBaseUrl}/risks/unknown`, unknownRisk)
      .pipe(
        switchMap(
          () => this.refetchRequest(),
          (risk: Risk) => risk
        )
      );
  }

  public replaceMedicalRisk(
    riskListItem: RiskListItem,
    riskToReplace: Risk
  ): Observable<ReplaceMedicalRiskResponse> {
    const replaceRiskRequest = {
      riskClass: riskListItem.riskClass,
      label: riskListItem.label,
      riskToReplaceId: riskToReplace.id,
    };
    return this.httpClient
      .put<ReplaceMedicalRiskResponse>(
        `${this.requestApiBaseUrl}/risks/medical`,
        replaceRiskRequest
      )
      .pipe(
        switchMap(
          () => this.refetchRequest(),
          (response: ReplaceMedicalRiskResponse) => response
        )
      );
  }

  public replaceMedicalRiskWithUnknown(
    riskLabel: string,
    riskToReplace: Risk
  ): Observable<ReplaceMedicalRiskResponse> {
    const replaceRiskRequest = {
      label: riskLabel,
      riskToReplaceId: riskToReplace.id,
    };
    return this.httpClient
      .put<any>(`${this.requestApiBaseUrl}/risks/unknown`, replaceRiskRequest)
      .pipe(
        switchMap(
          () => this.refetchRequest(),
          (response: ReplaceMedicalRiskResponse) => response
        )
      );
  }

  public downloadPaper(reason: string): Observable<Blob> {
    return this.httpClient
      .get(this.requestApiBaseUrl + `/medicalreport/pdf`, {
        responseType: 'blob',
      })
      .pipe(
        switchMap(
          () => this.setPaperDownloadStatus(reason),
          (blob: Blob) => blob
        )
      );
  }

  protected setPaperDownloadStatus(
    downloadReason: string
  ): Observable<Request> {
    return this.httpClient
      .post<void>(this.requestApiBaseUrl + `/paper-downloads`, {
        downloadReason,
      })
      .pipe(switchMap(() => this.refetchRequest()));
  }

  private flattenRisks(risks = new Array<Risk>()): Array<Risk> {
    const result = new Array<Risk>();

    risks.forEach((risk) => {
      result.push(risk);
      result.push(...this.flattenRisks(risk.routedRisks));
    });

    return result;
  }

  public isGeneralPhysicianRequest(request: Request): boolean {

    if(request.requestType !== 'physician') {
      return false;
    }

    const risks = request?.medicalRisks?.risks || [];

    if(risks.length === 0) {
      return true;
    }

    if (risks.every(risk => risk.source === 'added-by-physician')) {
      return true;
    }

    return false;
  }
}
