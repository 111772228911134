import { Component, Input, OnChanges } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TenantSpecificTextService } from '../../../../../../../idv-lib/src/lib/services/tenant/tenant-specific-text.service';

@Component({
  selector: 'idv-additional-diseases-step',
  templateUrl: './additional-diseases-step.component.html',
  styleUrls: ['./additional-diseases-step.component.scss'],
})
export class AdditionalDiseasesStepComponent implements OnChanges {
  @Input() hasExistingDiseases: boolean;

  @Input() selectedProducts: Array<string>;
  @Input() isGeneralPhysicianRequest: boolean;

  paragraph1$: Observable<string>;
  paragraph2$: Observable<string>;
  paragraph3$: Observable<string>;
  paragraph3AddOn$: Observable<string>;
  bulletPointsParagraphTwo$: Observable<Array<string>>;
  bulletPointsParagraphThree$: Observable<Array<string>>;

  constructor(private tenantSpecificTextService: TenantSpecificTextService) {}

  ngOnChanges(): void {
    const paragraph2Key = this.hasExistingDiseases
      ? 'addDiseaseWithDiseaseParagraphTwo'
      : 'addDiseaseWithoutDiseaseParagraphTwo';

    this.paragraph1$ = this.tenantSpecificTextService.getText(
      'addDiseaseParagraphOne'
    );

    const paragraph2Default$ =
      this.tenantSpecificTextService.getText(paragraph2Key);
    
    if (this.isGeneralPhysicianRequest) {
      const paragraph2GeneralPhysicianRequestOverrideKey = this
        .hasExistingDiseases
        ? 'addDiseaseWithDiseaseParagraphTwo_Override_GeneralPhysicianRequest'
        : 'addDiseaseWithoutDiseaseParagraphTwo_Override_GeneralPhysicianRequest';

      const paragraph2GeneralPhysicianRequestOverride$ =
        this.tenantSpecificTextService.getText(
          paragraph2GeneralPhysicianRequestOverrideKey
        );

      this.paragraph2$ = combineLatest([
          paragraph2GeneralPhysicianRequestOverride$,
          paragraph2Default$,
        ]).pipe(
          map(([overrideText, defaultText]) => overrideText ?? defaultText)
        );
    } else {
      this.paragraph2$ = this.tenantSpecificTextService.getText(paragraph2Key);
    }

    this.paragraph3$ = this.tenantSpecificTextService.getText(
      'addDiseaseParagraphThree'
    );
    this.paragraph3AddOn$ = this.tenantSpecificTextService.getText(
      'paragraphThreeAddOn'
    );
    this.bulletPointsParagraphTwo$ =
      this.tenantSpecificTextService.getBulletPointsParagraphTwo();
    this.bulletPointsParagraphThree$ =
      this.tenantSpecificTextService.getBulletPointsParagraphThree();
  }
}
