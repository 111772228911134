<div class="row">
  <div class="col-12">
    <h2 class="screen-header">
      {{ 'confirm-completeness.confirm-completeness' | translate }}
    </h2>

    <div>
      <idv-additional-diseases-text
        [hasExistingDiseases]="hasExistingDiseases"
        [selectedProducts]="selectedProducts"
        [isGeneralPhysicianRequest]="isGeneralPhysicianRequest"
        (allQuestionsConfirmed)="setCatchAllQuestionValueChange($event)"
      ></idv-additional-diseases-text>
    </div>
    <div>
      <idv-error-help-block
        *ngIf="showErrors && hasErrorsCatchAll"
        [errors]="{ required: true }"
      ></idv-error-help-block>
    </div>
    <div class="d-flex flex-column flex-md-row form-buttons mt-3">
      <button
        type="button"
        id="btn_idv_confirm_completeness"
        mat-raised-button
        color="primary"
        [disabled]="!allStepsCompleted || hasErrorsCatchAll"
        (click)="showNotificationModal()"
        idvClickTracker="idv_confirm_completeness_1_clicked"
      >
        {{ 'confirm-completeness.button' | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #notificationDialog>
  <idv-close-modal-header
    (close)="notificationDialogRef.close()"
  ></idv-close-modal-header>
  <div mat-dialog-content>
    <h2 class="mt-0">{{ 'confirm-completeness.heading' | translate }}</h2>
    <p>{{ 'confirm-completeness.confirm-text' | translate }}</p>
    <p>{{ 'confirm-completeness.no-further-documents' | translate }}</p>
    <mat-checkbox
      id="chk_confirm_physician_request"
      type="checkbox"
      [(ngModel)]="completenessConfirmed"
    >
      {{ 'confirm-completeness.can-not-be-changed' | translate }}
    </mat-checkbox>
  </div>
  <div
    mat-dialog-actions
    class="justify-content-center mb-0 pb-0"
  >
    <button
      class="flex-grow-1 flex-shrink-0"
      type="button"
      id="btn_accept_confirm_completeness_modal"
      mat-raised-button
      color="primary"
      [disabled]="!completenessConfirmed"
      (click)="confirm()"
      idvClickTracker="idv_confirm_completeness_2_clicked"
    >
      {{ 'confirm-completeness.button' | translate }}
    </button>
  </div>
</ng-template>
